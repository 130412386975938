import React from 'react'

function CopyIcon(props) {
	return (
		<svg width="10" height="11" viewBox="0 0 10 11" fill="none" {...props}>
			<path d="M5.95029 7.93992L4.09402 9.79632C3.16015 10.7302 1.64333 10.7345 0.703922 9.80942L0.703787 9.80955C0.701628 9.80739 0.699468 9.8051 0.697308 9.8028C0.695149 9.80064 0.692854 9.79848 0.69056 9.79632L0.690695 9.79619C-0.234404 8.85678 -0.23022 7.33997 0.703787 6.40609L2.74943 4.36046C3.6833 3.42658 5.20011 3.42226 6.13952 4.34736L6.13966 4.34723C6.14182 4.34939 6.14397 4.35168 6.14613 4.35384C6.14829 4.356 6.15059 4.35816 6.15288 4.36046L6.15275 4.36059C6.26504 4.47464 6.36371 4.59706 6.44861 4.72596L5.89279 5.28177C5.73433 5.44023 5.493 5.46574 5.30728 5.35938C5.28285 5.32861 5.25667 5.29892 5.22846 5.2703C4.7952 4.84487 4.09672 4.84703 3.66643 5.27732L1.62065 7.32296C1.19009 7.75352 1.18807 8.45241 1.61417 8.88567C2.04744 9.31177 2.74646 9.30975 3.17688 8.87919L4.33967 7.7164C4.84946 7.93749 5.40784 8.01186 5.95029 7.93992ZM9.29625 1.1905L9.29611 1.19063C8.35671 0.265535 6.83989 0.269719 5.90602 1.20373L4.04961 3.06013C4.5922 2.98819 5.15058 3.06256 5.66023 3.28351L6.82302 2.12073C7.25358 1.69017 7.95247 1.68814 8.38573 2.11425C8.81183 2.54751 8.80981 3.24653 8.37925 3.67696L6.33361 5.72273C5.90332 6.15302 5.20484 6.15518 4.77158 5.72975C4.7435 5.70113 4.71718 5.67144 4.69275 5.64067C4.50703 5.53431 4.2657 5.55982 4.10725 5.71828L3.55143 6.27409C3.63633 6.40299 3.73499 6.52554 3.84729 6.63946L3.84715 6.6396C3.84931 6.64176 3.85161 6.64391 3.8539 6.64621C3.85606 6.64837 3.85822 6.65066 3.86038 6.65282L3.86052 6.65269C4.79992 7.57779 6.31674 7.5736 7.25061 6.6396L9.29625 4.59396C10.2301 3.66008 10.2344 2.14327 9.30934 1.20386L9.30948 1.20373C9.30732 1.20157 9.30502 1.19941 9.30273 1.19725C9.30057 1.19495 9.29841 1.19279 9.29625 1.1905Z" fill="#5350F2" />
		</svg>
	)
}

export default CopyIcon
